body {
  font-family: 'Poppins', sans-serif;
}

.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 12px;
  padding-left: 12px
}

@media (min-width: 540px) {
  .container {
      max-width:540px
  }
}

@media (min-width: 640px) {
  .container {
      max-width:640px;
      padding-right: 1rem;
      padding-left: 1rem
  }
}

@media (min-width: 768px) {
  .container {
      max-width:768px
  }
}

@media (min-width: 992px) {
  .container {
      max-width:992px
  }
}

@media (min-width: 1024px) {
  .container {
      max-width:1024px;
      padding-right: 45px;
      padding-left: 45px
  }
}

@media (min-width: 1280px) {
  .container {
      max-width:1280px;
      padding-right: 5rem;
      padding-left: 5rem
  }
}

@media (min-width: 1536px) {
  .container {
      max-width:1536px;
      padding-right: 13rem;
      padding-left: 13rem
  }
}